body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  padding: 0;

  /* OLD WEBSITE CSS */
  /* --fonts: 'Numans', sans-serif;
  font-family: var(--fonts);
  --primary-color: #444;
  --secondary-color: #eece1a;
  background: var(--primary-color);
  color: white;
  overflow: hidden; */

  /* NEW WEBSITE CSS */
  /* CSS HEX */
  --light-coral: #ef7674ff;
  --fiery-rose: #ec5766ff;
  --rusty-red: #da344dff;
  --crimson: #d91e36ff;
  --bright-maroon: #c42348ff;
  --violet-blue-crayola: #6369d1ff;
  --medium-turquoise: #60e1e0ff;
  --independence: #34435eff;
  --rich-black-fogra: #020202ff;
  --prussian-blue: #0d324dff;

  --light-coral-rgb: rgba(239, 118, 116, 0.8);
  --rusty-red-rgb: rgba(202, 46, 83, 0.8);
  --crimson-rgb: rgba(190, 0, 25, 0.7);
  --bright-maroon-rgb: rgba(255, 0, 60, 0.7);
  --violet-blue-crayola-rgb: rgba(129, 43, 199, 0.8);
  --fiery-orange-rgb: rgba(227, 152, 94, 0.85);

  --fonts: 'Cousine', monospace;
  --title: 'Bona Nova', serif;
  font-family: var(--fonts);
  min-height: 100vh;
  overflow-x: hidden;
}

::-webkit-scrollbar-corner {
  background: transparent;
  /* OLD WEBSITE CSS */
  /* background: rgba(255, 255, 255, 0.1); */
}

::-webkit-scrollbar {
  width: 8px;
  background: transparent;
  /* OLD WEBSITE CSS */
  /* background: rgba(34, 34, 34, 0.7);
  height: 8px;
  width: 5px; */
}

::-webkit-scrollbar-thumb {
  background: var(--prussian-blue);
  border-radius: 50px;
  /* OLD WEBSITE CSS */
  /* background: var(--secondary-color);
  opacity: 1;
  border-radius: 50px; */
}

::selection {
  color: white;
  background: black;
  /* OLD WEBSITE CSS */
  /* background: var(--secondary-color);
  color: black; */
}

audio::-webkit-media-controls-panel {
  /* OLD WEBSITE CSS */
  /* background: #777; */
  background: #f2f2f2;
}

audio::-webkit-media-controls-play-button {
  /* OLD WEBSITE CSS */
  /* background-color: var(--secondary-color);
  min-height: 40px;
  min-width: 40px;
  max-height: 40px;
  max-width: 40px;
  border-radius: 50%; */
  min-height: 25px;
  min-width: 25px;
  max-height: 25px;
  max-width: 25px;
  border-radius: 50%;
}

b {
  /* OLD WEBSITE CSS */
  /* color: var(--secondary-color); */
}

code {
  /* OLD WEBSITE CSS */
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
